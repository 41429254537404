const talentData = [
  {
    idNum: 1,
    id: "r1",
    aspect: "race",
    name: "Dwarf",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: true,
    rating: 3,
    preq: "Can only be taken at 1st level. Can't have another race talent.",
    bonus: "special",
    img: "talent-race-dwarf.png",
  },
  {
    idNum: 2,
    id: "r2",
    aspect: "race",
    name: "Elf",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: true,
    rating: 3,
    preq: "Can only be taken at 1st level. Can't have another race talent.",
    bonus: "special",
    img: "talent-race-elf.png",
  },
  {
    idNum: 3,
    id: "r3",
    aspect: "race",
    name: "Gnome",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: true,
    rating: 3,
    preq: "Can only be taken at 1st level. Can't have another race talent.",
    bonus: "special",
    img: "",
  },
  {
    idNum: 4,
    id: "r4",
    aspect: "race",
    name: "Half-Elf",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: true,
    rating: 3,
    preq: "Can only be taken at 1st level. Can't have another race talent.",
    bonus: "special",
    img: "talent-race-half-elf.png",
  },
  {
    idNum: 5,
    id: "r5",
    aspect: "race",
    name: "Half-Orc",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: true,
    rating: 3,
    preq: "Can only be taken at 1st level. Can't have another race talent.",
    bonus: "special",
    img: "talent-tace-half-orc.png",
  },
  {
    idNum: 6,
    id: "r6",
    aspect: "race",
    name: "Halfling",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: true,
    rating: 3,
    preq: "Can only be taken at 1st level. Can't have another race talent.",
    bonus: "special",
    img: "",
  },
  {
    idNum: 7,
    id: "r7",
    aspect: "race",
    name: "Mutant",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: false,
    rating: "",
    preq: "Can only be taken at 1st level. Can't have another race talent.",
    bonus: "special",
    img: "",
  },

  {
    idNum: 8,
    id: "c1",
    aspect: "common",
    name: "Alertness",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: true,
    rating: 3,
    preq: "",
    bonus: "",
    img: "",
  },
  {
    idNum: 9,
    id: "c2",
    aspect: "common",
    name: "Attribute Increase",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: true,
    isRollable: true,
    rating: "",
    preq: "",
    bonus: "special",
    img: "",
  },
  {
    idNum: 10,
    id: "c3",
    aspect: "common",
    name: "Medical",
    desc: "copy",
    mod: "INT",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: 2,
    preq: "",
    bonus: "",
    img: "",
  },

  {
    idNum: 11,
    id: "c4",
    aspect: "common",
    name: "Psychic Sensitivity",
    desc: "copy",
    mod: "WIS",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    idSort: "c4",
    preq: "",
    bonus: "",
    img: "",
  },

  {
    idNum: 12,
    id: "c5",
    aspect: "common",
    name: "Riding",
    desc: "copy",
    mod: "DEX",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: 2,
    preq: "",
    bonus:
      "+2 points distributed in any combination to STR, DEX, or CON. None can exceed racial max (18 for humans).",
    img: "",
  },

  {
    idNum: 13,
    id: "c6",
    aspect: "common",
    name: "Scholarly Knowledge",
    desc: "copy",
    mod: "INT/WIS",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    idSort: "c6",
    preq: "",
    bonus: "",
    img: "",
  },

  {
    idNum: 14,
    id: "c7",
    aspect: "common",
    name: "Stronghold",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: false,
    rating: 2,
    preq: "9th level or higher",
    bonus: "+1 to WIS or CHA. Neither can exceed racial max (18 for humans).",
    img: "talent-stronghold.png",
  },
  {
    idNum: 15,
    id: "c8",
    aspect: "common",
    name: "Survival & Tracking",
    desc: "copy",
    mod: "none",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus: "+1 to WIS or CON. Neither can exceed racial max (18 for humans).",
    img: "talent-survival-&-tracking.png",
  },
  {
    idNum: 16,
    id: "c9",
    aspect: "common",
    name: "Wild Psionics",
    desc: "cony",
    mod: "INT, WIS, or CHA",
    isLeveling: false,
    isStacking: true,
    isRollable: true,
    rating: "",
    preq: "Can only be taken at 1st level. Cannot have taken the Psionics talent.",
    bonus: "",
    img: "",
  },

  {
    idNum: 17,
    id: "f1",
    aspect: "fighter",
    name: "Berserk",
    desc: "copy",
    mod: "none",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    idSort: "f1",
    preq: "",
    bonus: "",
    img: "",
  },
  {
    idNum: 18,
    id: "f2",
    aspect: "fighter",
    name: "Blind Fighting",
    desc: "copy",
    mod: "WIS",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus:
      "+2 points distributed to WIS or DEX in any combination. None can exceed racial max (18 for humans).",
    img: "",
  },
  {
    idNum: 19,
    id: "f3",
    aspect: "fighter",
    name: "Combat",
    desc: "copy",
    mod: "STR/DEX",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus: "",
    img: "",
  },
  {
    idNum: 20,
    id: "f4",
    aspect: "fighter",
    name: "Combat Specialization",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: true,
    rating: 4,
    preq: "",
    bonus: "",
    img: "",
  },
  {
    idNum: 21,
    id: "f5",
    aspect: "fighter",
    name: "Durability",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus: "",
    img: "",
  },
  {
    idNum: 22,
    id: "f6",
    aspect: "fighter",
    name: "Multi-Attack",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: true,
    rating: 4,
    preq: "",
    bonus: "",
    img: "",
  },
  {
    idNum: 23,
    id: "f7",
    aspect: "fighter",
    name: "Missile Deflection",
    desc: "copy",
    mod: "DEX",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus: "",
    img: "",
  },

  {
    idNum: 24,
    id: "p1",
    aspect: "priest",
    name: "Divine Attunement",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus: "",
    img: "talent-divine-attunement.png",
  },
  {
    idNum: 25,
    id: "p2",
    aspect: "priest",
    name: "Divine Shapeshift",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus: "",
    img: "",
  },
  {
    idNum: 26,
    id: "p3",
    aspect: "priest",
    name: "Holy Aura",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus: "",
    img: "",
  },
  {
    idNum: 27,
    id: "p4",
    aspect: "priest",
    name: "Incorruptibility",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus: "",
    img: "",
  },

  {
    idNum: 28,
    id: "p5",
    aspect: "priest",
    name: "Psionics",
    desc: "copy",
    mod: "INT, WIS, or CHA",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    idSort: "p5",
    preq: "Can only be taken at 1st level. Cannot have taken either the Thaumaturgy or Wild Psionics talent.",
    bonus: "",
    img: "",
  },

  {
    idNum: 29,
    id: "p6",
    aspect: "priest",
    name: "Psionic Amplification",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: false,
    rating: "",
    idSort: "p5",
    preq: "Psionics",
    bonus: "",
    img: "",
  },
  {
    idNum: 30,
    id: "p7",
    aspect: "priest",
    name: "Sermonize",
    desc: "copy",
    mod: "CHA",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus: "+1 to WIS or CHA. Neither can exceed racial max (18 for humans).",
    img: "talent-sermonize.png",
  },

  {
    idNum: 31,
    id: "p8",
    aspect: "priest",
    name: "Thaumaturgy",
    desc: "copy",
    mod: "WIS",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: 5,
    preq: "Cannot take Psionics",
    bonus: "",
    img: "",
  },
  {
    idNum: 32,
    id: "p9",
    aspect: "priest",
    name: "Turn Undead",
    desc: "copy",
    mod: "WIS",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus: "",
    img: "",
  },

  {
    idNum: 33,
    id: "w1",
    aspect: "wizard",
    name: "Arcane Scholarship",
    desc: "copy",
    mod: "INT",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus:
      "+1 to INT or WIS. Neither can exceed racial max (18 for humans). One extra Wizard spell slot of any level the user can cast (must have Wizardry 1 to gain this bonus).",
    img: "",
  },
  {
    idNum: 34,
    id: "w2",
    aspect: "wizard",
    name: "Battle Casting",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: false,
    rating: "",
    preq: "Wizardry 1",
    bonus: "",
    img: "",
  },
  {
    idNum: 35,
    id: "w3",
    aspect: "wizard",
    name: "Spell Refashionment",
    desc: "copy",
    mod: "INT",
    isLeveling: true,
    isStacking: false,
    isRollable: false,
    rating: "",
    preq: "Wizardry 1",
    bonus: "",
    img: "",
  },
  {
    idNum: 36,
    id: "w4",
    aspect: "wizard",
    name: "Stealth Casting",
    desc: "copy",
    mod: "INT",
    isLeveling: true,
    isStacking: false,
    isRollable: false,
    rating: "",
    preq: "Thaumaturgy or Wizardry",
    bonus: "",
    img: "",
  },
  {
    idNum: 37,
    id: "w5",
    aspect: "wizard",
    name: "Wizardry 1",
    desc: "copy",
    mod: "INT",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: 5,
    preq: "",
    bonus: "",
    img: "",
  },
  {
    idNum: 38,
    id: "w6",
    aspect: "wizard",
    name: "Wizardry 2",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: false,
    rating: "",
    preq: "Wizardry 1",
    bonus: "",
    img: "",
  },
  {
    idNum: 39,
    id: "w7",
    aspect: "wizard",
    name: "Wizardry 3",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: false,
    rating: "",
    preq: "Wizardry 2",
    bonus: "",
    img: "",
  },

  {
    idNum: 40,
    id: "k1",
    aspect: "knave",
    name: "Acrobatics",
    desc: "copy",
    mod: "DEX",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus:
      "+2 points distributed in any combination to STR, DEX, or CON. None can exceed racial max (18 for humans).",
    img: "",
  },
  {
    idNum: 41,
    id: "k2",
    aspect: "knave",
    name: "Assassination",
    desc: "copy",
    mod: "INT",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus:
      "+2 points distributed in any combination to INT, CHA, or DEX. None can exceed racial max (18 for humans).",
    img: "",
  },
  {
    idNum: 42,
    id: "k3",
    aspect: "knave",
    name: "Backstabbing",
    desc: "copy",
    mod: "none",
    isLeveling: false,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus: "",
    img: "",
  },
  {
    idNum: 43,
    id: "k4",
    aspect: "knave",
    name: "Beguilement",
    desc: "copy",
    mod: "CHA",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus: "",
    img: "",
  },

  {
    idNum: 44,
    id: "k5",
    aspect: "knave",
    name: "Burglary",
    desc: "copy",
    mod: "DEX",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus: "",
    img: "",
  },
  {
    idNum: 45,
    id: "k6",
    aspect: "knave",
    name: "Climbing",
    desc: "copy",
    mod: "DEX",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus:
      "+2 points distributed in any combination to STR, DEX, or CON. None can exceed racial max (18 for humans).",
    img: "talent-climbing.png",
  },
  {
    idNum: 46,
    id: "k7",
    aspect: "knave",
    name: "Disguise",
    desc: "copy",
    mod: "CHA",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus:
      "+2 points distributed in any combination to CHA, WIS, or DEX. None can exceed racial max (18 for humans).",
    img: "",
  },
  {
    idNum: 47,
    id: "k8",
    aspect: "knave",
    name: "Escapology",
    desc: "copy",
    mod: "DEX/INT",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus:
      "+2 points distributed in any combination to INT, WIS, or DEX. None can exceed racial max (18 for humans).",
    img: "",
  },
  {
    idNum: 48,
    id: "k9",
    aspect: "knave",
    name: "Inspiration",
    desc: "copy",
    mod: "CHA",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus: "",
    img: "talent-inspiration.png",
  },
  {
    idNum: 49,
    id: "k10",
    aspect: "knave",
    name: "Sleight of hand",
    desc: "copy",
    mod: "DEX",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus:
      "+2 points distributed in any combination to DEX or CHA. None can exceed racial max (18 for humans).",
    img: "",
  },
  {
    idNum: 50,
    id: "k11",
    aspect: "knave",
    name: "Stealth",
    desc: "copy",
    mod: "DEX",
    isLeveling: true,
    isStacking: false,
    isRollable: true,
    rating: "",
    preq: "",
    bonus: "",
    img: "",
  },
];

export { talentData };
