export const spellSlots = [
  [1, "-", "-", "-", "-", "-", "-"],
  [2, "-", "-", "-", "-", "-", "-"],
  [2, 1, "-", "-", "-", "-", "-"],
  [3, 2, "-", "-", "-", "-", "-"],
  [3, 2, 1, "-", "-", "-", "-"],
  [4, 3, 1, "-", "-", "-", "-"],
  [4, 3, 2, 1, "-", "-", "-"],
  [4, 3, 3, 2, "-", "-", "-"],
  [4, 3, 3, 2, 1, "-", "-"],
  [4, 4, 3, 3, 2, "-", "-"],
  [4, 4, 3, 3, 2, 1, "-"],
  [4, 4, 4, 3, 2, 2, "-"],
  [4, 4, 4, 3, 2, 2, 1],
];
export const magicSchoolsData = [
  {
    id: 0,
    name: "white",
    desc: "Life, light, healing, revelation, protection",
    isDisabled: false,
  },
  {
    id: 1,
    name: "black",
    desc: "Death, darkness, slumber, insanity, disease, demonology",
    isDisabled: false,
  },
  {
    id: 2,
    name: "green",
    desc: "Nature, weather, fecundity, water, air, ice",
    isDisabled: false,
  },
  {
    id: 3,
    name: "blue",
    desc: "Illusion, transformation, charms, trickery",
    isDisabled: false,
  },
  {
    id: 4,
    name: "red",
    desc: "Fire, earth, destruction, raw power",
    isDisabled: false,
  },
];

export const thaumaturgyList = [
  [
    "Aura of Protection",
    "Comprehend Languages (Incomprehensible Languages)",
    "Cure Light Wounds (Cause Light Wounds)",
    "Detect Magic",
    "Light (Darkness)",
    "Purify Food & Water (Putrify Food & Water)",
    "Remove Fear (Cause Fear)",
    "Resist Cold",
  ],
  [
    "Augury",
    "Bless (Curse)",
    "Create Food & Water",
    "Detect Traps",
    "Hold Person",
    "Resist Fire",
    "Silence 15' Radius",
    "Speak with Animals",
  ],
  [
    "Animate Dead",
    "Bless Weapon",
    "Cure Disease (Cause Disease)",
    "Counterspell",
    "Dispel Magic",
    "Feign Death",
    "Locate Object",
    "Remove Curse (Curse)",
  ],
  [
    "Aura of Protection, 10' radius",
    "Cure Serious Wounds (Cause Serious Wounds)",
    "Detect Lie (Undetectable Lie)",
    "Neutralize Poison (Poison)",
    "Speak with Plants",
    "Sticks to Snakes",
    "Tongues (Gibberish)",
  ],
  [
    "Banishment",
    "Commune",
    "Cure Critical Wounds (Cause Critical Wounds)",
    "Insect Plague",
    "Plane Shift",
    "Quest (Remove Quest)",
    "Raise Dead (Finger of Death)",
    "Suspend Life",
    "True Seeing",
  ],
  [
    "Find the Path (Lose the Path)",
    "Heal (Harm)",
    "Hurricane",
    "Part Water",
    "Word of Recall",
  ],
  [
    "Earthquake",
    "Gate",
    "Holy Word (Unholy Word)",
    "Regenerate",
    "Resurrection (Annihilation)",
    "Symbol",
  ],
];

export const wizardryList = [
  [
    "Aura of Protection",
    "Burning Hands",
    "Charm Person",
    "Comprehend Languages (Incomprehensible Languages)",
    "Darksight",
    "Detect Magic",
    "Entangle",
    "Feather Fall",
    "Light (Darkness)",
    "Magic Missile",
    "Pass Without Trace",
    "Phantom Image",
    "Resist Cold",
    "Shield",
    "Sleep",
    "Speak with Animals",
    "Spider Climb",
    "Visage",
  ],
  [
    "Alter Self",
    "Barkskin",
    "Continual Light (Continual Darkness)",
    "Detect Invisibility",
    "ESP",
    "Feign Death",
    "Forget",
    "Invisibility",
    "Levitate",
    "Locate Object",
    "Mirror Image",
    "Negate Invisibility",
    "Phantasmal Force",
    "Pyrotechnics",
    "Ray of Enfeeblement",
    "Resist Fire",
    "Speak with Plants",
    "Stinking Cloud",
    "Vampiric Grasp",
    "Web",
    "Wizard Lock (Wizard Knock)",
  ],
  [
    "Animal Summoning",
    "Aura of Protection, 10' radius",
    "Clairvoyance",
    "Counterspell",
    "Dispel Magic",
    "Fauna Form",
    "Fireball",
    "Fly",
    "Haste",
    "Hold Person",
    "Invisibility, 10' radius",
    "Lightning Bolt",
    "Minor Creation",
    "Protection From Normal Missiles",
    "Shadow Monsters",
    "Slow Spell",
    "Sticks to Snakes",
    "Suggestion",
    "Water Breathing",
  ],
  [
    "Amnesia",
    "Charm Monster",
    "Confusion",
    "Dimension Door",
    "Fire Charm",
    "Fire Seeds",
    "Fire Trap",
    "Hallucinatory Terrain",
    "Insect Plague",
    "Polymorph Other",
    "Polymorph Self",
    "Reincarnation",
    "Remove Curse (Curse)",
    "Repulsion",
    "Vampiric Transfusion",
    "Wall of Fire",
    "Wall of Ice",
    "Wizard Eye",
  ],
  [
    "Animate Dead",
    "Cloudkill",
    "Demi-Shadow Monsters",
    "Feeblemind",
    "Hold Monster",
    "Major Creation",
    "Passwall",
    "Projected Image",
    "Rust",
    "Scrying",
    "Suspend Life",
    "Telekinesis",
    "Teleport",
    "Veil",
    "Wall of Force",
    "Wall of Thorns",
  ],
  [
    "Anti-Magic Shell",
    "Banishment",
    "Creeping Doom",
    "Disintegrate",
    "Hurricane",
    "Legend Lore",
    "Mass Suggestion",
    "Part Water",
    "Shades",
    "Spell Fork",
    "True Seeing",
  ],
  [
    "Earthquake",
    "Gate",
    "Limited Wish",
    "Mirage Arcana",
    "Simulacrum",
    "Vision",
  ],
];

export const spellData = {
  "Alter Self": {
    cast: "1 action",
    range: "0'",
    target: "caster",
    duration: "10 min",
    school: ["blue"],
    components: "V,S",
    save: "none",
    wizLvl: 2,
    priestLvl: 0,
  },
  Amnesia: {
    cast: "1 action",
    range: "120'",
    target: "1 creature",
    duration: "permanent",
    school: ["black"],
    components: "V,S",
    save: "negate",
    wizLvl: 4,
    priestLvl: 0,
  },
  "Animal Summoning": {
    cast: "1 action",
    range: "30'",
    target: "special",
    duration: "1 hour/level",
    school: ["green"],
    components: "V,S",
    save: "none",
    wizLvl: 3,
    priestLvl: 0,
  },
  "Animate Dead": {
    cast: "1 turn",
    range: "30'",
    target: "special",
    duration: "permanent",
    school: ["black"],
    components: "V,S",
    save: "none",
    wizLvl: 5,
    priestLvl: 3,
  },
  "Anti-Magic Shell": {
    cast: "1 action",
    range: "0'",
    target: "caster",
    duration: "10 min/level",
    school: ["black", "blue", "green", "red", "white"],
    components: "V,S",
    save: "none",
    wizLvl: 6,
    priestLvl: 0,
  },
  Augury: {
    cast: "1 turn",
    range: "0'",
    target: "special",
    duration: "special",
    school: null,
    components: "V,S,M",
    save: "none",
    wizLvl: 0,
    priestLvl: 2,
  },
  "Aura of Protection": {
    cast: "1 action",
    range: "0'",
    target: "1 creature",
    duration: "1 hour",
    school: ["black", "white"],
    components: "V,S,M",
    save: "none",
    wizLvl: 1,
    priestLvl: 1,
  },
  "Aura of Protection, 10' radius": {
    cast: "1 min",
    range: "0'",
    target: "10' radius",
    duration: "1 hour",
    school: ["black", "white"],
    components: "V,S,M",
    save: "none",
    wizLvl: 3,
    priestLvl: 4,
  },
  Banishment: {
    cast: "1 action",
    range: "120'",
    target: "1 creature/4 levels",
    duration: "instant",
    school: ["black", "white"],
    components: "V,S",
    save: "negate",
    wizLvl: 6,
    priestLvl: 5,
  },
  Barkskin: {
    cast: "1 action",
    range: "0'",
    target: "1 creature",
    duration: "1 hour",
    school: ["green"],
    components: "V,S,M",
    save: "none",
    wizLvl: 2,
    priestLvl: 0,
  },
  "Bless (Curse)": {
    cast: "1 action",
    range: "60'",
    target: "20' cube",
    duration: "1 hour",
    school: null,
    components: "V,S,M",
    save: "negate",
    wizLvl: 0,
    priestLvl: 2,
  },
  "Bless Weapon": {
    cast: "1 action",
    range: "30'",
    target: "1 weapon",
    duration: "10 min",
    school: null,
    components: "V,S,M",
    save: "none",
    wizLvl: 0,
    priestLvl: 3,
  },
  "Burning Hands": {
    cast: "1 action",
    range: "0'",
    target: "15' cone",
    duration: "1 round",
    school: ["red"],
    components: "V,S",
    save: "half",
    wizLvl: 1,
    priestLvl: 0,
  },
  "Charm Monster": {
    cast: "1 action",
    range: "120'",
    target: "1 creature",
    duration: "1 hour/level",
    school: ["blue"],
    components: "V,S",
    save: "negate",
    wizLvl: 4,
    priestLvl: 0,
  },
  "Charm Person": {
    cast: "1 action",
    range: "90'",
    target: "1 humanoid",
    duration: "1 hour/level",
    school: ["blue"],
    components: "V,S",
    save: "negate",
    wizLvl: 1,
    priestLvl: 0,
  },
  Clairvoyance: {
    cast: "1 min",
    range: "480'",
    target: "special",
    duration: "1 round/level",
    school: ["white"],
    components: "V,S",
    save: "none",
    wizLvl: 3,
    priestLvl: 0,
  },
  Cloudkill: {
    cast: "1 action",
    range: "60'",
    target: "30' diameter",
    duration: "4 rounds + 1 round/level",
    school: ["black", "green"],
    components: "V,S,M",
    save: "negate",
    wizLvl: 5,
    priestLvl: 0,
  },
  Commune: {
    cast: "1 hour",
    range: "0'",
    target: "caster",
    duration: "1 min",
    school: null,
    components: "V,S,M",
    save: "none",
    wizLvl: 0,
    priestLvl: 5,
  },
  "Comprehend Languages (Incomprehensible Languages)": {
    cast: "1 action",
    range: "0'",
    target: "1 creature or written document",
    duration: "20 min",
    school: ["black", "white"],
    components: "V,S",
    save: "negate",
    wizLvl: 1,
    priestLvl: 1,
  },
  Confusion: {
    cast: "1 action",
    range: "120'",
    target: "1d6 creatures / 3 levels within 40ft radius",
    duration: "1 min/level",
    school: ["black", "blue"],
    components: "V,S,M",
    save: "negate",
    wizLvl: 4,
    priestLvl: 0,
  },
  "Continual Light (Continual Darkness)": {
    cast: "1 action",
    range: "90'",
    target: "special",
    duration: "permanent",
    school: ["black", "red", "white"],
    components: "V,S",
    save: "negate",
    wizLvl: 2,
    priestLvl: 0,
  },
  Counterspell: {
    cast: "instant",
    range: "120'",
    target: "1 spell being cast within range",
    duration: "permanent",
    school: ["black", "blue", "green", "red", "white"],
    components: "V,S",
    save: "none",
    wizLvl: 3,
    priestLvl: 3,
  },
  "Create Food & Water": {
    cast: "1 turn",
    range: "30'",
    target: "special",
    duration: "permanent",
    school: null,
    components: "V,S",
    save: "none",
    wizLvl: 0,
    priestLvl: 2,
  },
  "Creeping Doom": {
    cast: "1 action",
    range: "60'",
    target: "20' square",
    duration: "4 rounds/level",
    school: ["green"],
    components: "V,S",
    save: "special",
    wizLvl: 6,
    priestLvl: 0,
  },
  "Cure Critical Wounds (Cause Critical Wounds)": {
    cast: "1 action",
    range: "0'",
    target: "1 creature",
    duration: "permanent",
    school: null,
    components: "V,S,M",
    save: "none",
    wizLvl: 0,
    priestLvl: 5,
  },
  "Cure Disease (Cause Disease)": {
    cast: "1 turn",
    range: "0'",
    target: "1 creature",
    duration: "special",
    school: null,
    components: "V,S",
    save: "negate",
    wizLvl: 0,
    priestLvl: 3,
  },
  "Cure Light Wounds (Cause Light Wounds)": {
    cast: "1 action",
    range: "0'",
    target: "1 creature",
    duration: "permanent",
    school: null,
    components: "V,S,M",
    save: "none",
    wizLvl: 0,
    priestLvl: 1,
  },
  "Cure Serious Wounds (Cause Serious Wounds)": {
    cast: "1 action",
    range: "0'",
    target: "1 creature",
    duration: "permanent",
    school: null,
    components: "V,S,M",
    save: "none",
    wizLvl: 0,
    priestLvl: 4,
  },
  Darksight: {
    cast: "1 action",
    range: "0'",
    target: "1 creature per level of caster",
    duration: "1 hour + 10 min/level",
    school: ["black", "Green", "white"],
    components: "V,S,",
    save: "none",
    wizLvl: 1,
    priestLvl: 0,
  },
  "Demi-Shadow Monsters": {
    cast: "1 action",
    range: "120'",
    target: "20' cube",
    duration: "1 hour/level",
    school: ["black", "blue"],
    components: "V,S,",
    save: "special",
    wizLvl: 5,
    priestLvl: 0,
  },
  "Detect Invisibility": {
    cast: "1 action",
    range: "10'/level",
    target: "caster",
    duration: "1 hour",
    school: ["blue", "white"],
    components: "V,S,M",
    save: "none",
    wizLvl: 2,
    priestLvl: 0,
  },
  "Detect Lie (Undetectable Lie)": {
    cast: "1 action",
    range: "60'",
    target: "caster",
    duration: "1 round/level",
    school: null,
    components: "V,S,M",
    save: "none",
    wizLvl: 0,
    priestLvl: 4,
  },
  "Detect Magic": {
    cast: "1 action",
    range: "0'",
    target: "60' radius",
    duration: "20 min",
    school: ["black", "blue", "green", "red", "white"],
    components: "V,S",
    save: "none",
    wizLvl: 1,
    priestLvl: 1,
  },
  "Detect Traps": {
    cast: "1 action",
    range: "0'",
    target: "30' radius",
    duration: "20 min",
    school: null,
    components: "V,S",
    save: "none",
    wizLvl: 0,
    priestLvl: 2,
  },
  "Dimension Door": {
    cast: "1 action",
    range: "480'",
    target: "caster and 1 other creature",
    duration: "instant",
    school: ["red"],
    components: "V,S,M",
    save: "negate",
    wizLvl: 4,
    priestLvl: 0,
  },
  Disintegrate: {
    cast: "1 action",
    range: "120'",
    target: "special",
    duration: "permanent",
    school: ["red"],
    components: "V,S,M",
    save: "negate",
    wizLvl: 6,
    priestLvl: 0,
  },
  "Dispel Magic": {
    cast: "1 action",
    range: "120'",
    target: "1 spell or 20' cube",
    duration: "instant",
    school: ["black", "blue", "green", "red", "white"],
    components: "V,S",
    save: "none",
    wizLvl: 3,
    priestLvl: 3,
  },
  Earthquake: {
    cast: "1 action",
    range: "240'",
    target: "5' diameter/level",
    duration: "1 round",
    school: ["green", "red"],
    components: "V,S,M",
    save: "special",
    wizLvl: 7,
    priestLvl: 7,
  },
  Entangle: {
    cast: "1 action",
    range: "90'",
    target: "50' diameter",
    duration: "10 min",
    school: ["green"],
    components: "V,S,M",
    save: "half",
    wizLvl: 1,
    priestLvl: 0,
  },
  ESP: {
    cast: "1 action",
    range: "60'",
    target: "special",
    duration: "2d4 rounds + 1 round/level",
    school: ["white"],
    components: "V,S,M",
    save: "none",
    wizLvl: 2,
    priestLvl: 0,
  },
  "Fauna Form": {
    cast: "1 action",
    range: "0'",
    target: "caster",
    duration: "1 hour",
    school: ["green"],
    components: "V,S",
    save: "none",
    wizLvl: 3,
    priestLvl: 0,
  },
  "Feather Fall": {
    cast: "instant",
    range: "90'",
    target: "3 creatures +1 creature per 2 levels",
    duration: "1 min",
    school: ["green"],
    components: "V,M",
    save: "negate",
    wizLvl: 1,
    priestLvl: 0,
  },
  Feeblemind: {
    cast: "1 action",
    range: "60'",
    target: "1 creature",
    duration: "permanent",
    school: ["black"],
    components: "V,S",
    save: "negate",
    wizLvl: 5,
    priestLvl: 0,
  },
  "Feign Death": {
    cast: "1 action",
    range: "0'",
    target: "1 creature",
    duration: "1 hour + 1 hour/level",
    school: ["black"],
    components: "V,S",
    save: "none",
    wizLvl: 2,
    priestLvl: 3,
  },
  "Find the Path (Lose the Path)": {
    cast: "1 action",
    range: "0'",
    target: "1 creature",
    duration: "10 min/level",
    school: null,
    components: "V,S,M",
    save: "none",
    wizLvl: 0,
    priestLvl: 6,
  },
  "Fire Charm": {
    cast: "1 action",
    range: "120'",
    target: "30' diameter around 1 fire",
    duration: "1 min/level",
    school: ["red"],
    components: "V,S,M",
    save: "none",
    wizLvl: 4,
    priestLvl: 0,
  },
  "Fire Seeds": {
    cast: "1 action",
    range: "0'",
    target: "special",
    duration: "special",
    school: ["green", "red"],
    components: "V,S,M",
    save: "none",
    wizLvl: 4,
    priestLvl: 0,
  },
  "Fire Trap": {
    cast: "1 min",
    range: "0'",
    target: "1 object",
    duration: "until discharged",
    school: ["red"],
    components: "V,S,M",
    save: "none",
    wizLvl: 4,
    priestLvl: 0,
  },
  Fireball: {
    cast: "1 action",
    range: "240'",
    target: "20' radius",
    duration: "instant",
    school: ["red"],
    components: "V,S,M",
    save: "half",
    wizLvl: 3,
    priestLvl: 0,
  },
  Fly: {
    cast: "1 action",
    range: "0'",
    target: "1 creature",
    duration: "10 min/level + 10-60 min",
    school: ["green", "red"],
    components: "V,S",
    save: "none",
    wizLvl: 3,
    priestLvl: 0,
  },
  Forget: {
    cast: "1 action",
    range: "120'",
    target: "up to 6 creatures within 40'x40' area",
    duration: "permanent",
    school: ["black"],
    components: "V,S",
    save: "negate",
    wizLvl: 2,
    priestLvl: 0,
  },
  Gate: {
    cast: "1 turn",
    range: "30'",
    target: "special",
    duration: "concentration or 1 round/level",
    school: ["white", "black"],
    components: "V,S",
    save: "none",
    wizLvl: 7,
    priestLvl: 7,
  },
  "Hallucinatory Terrain": {
    cast: "1 min",
    range: "0'",
    target: "240' radius",
    duration: "special",
    school: ["blue"],
    components: "V,S",
    save: "none",
    wizLvl: 4,
    priestLvl: 0,
  },
  Haste: {
    cast: "1 action",
    range: "240'",
    target: "1 creature/level within 20' radius",
    duration: "3 rounds + 1 round/level",
    school: ["red"],
    components: "V,S,M",
    save: "none",
    wizLvl: 3,
    priestLvl: 0,
  },
  "Heal (Harm)": {
    cast: "1 action",
    range: "0'",
    target: "1 creature",
    duration: "permanent",
    school: null,
    components: "V,S,M",
    save: "none",
    wizLvl: 0,
    priestLvl: 6,
  },
  "Hold Monster": {
    cast: "1 action",
    range: "120'",
    target: "1 creature or 1 group within 20' radius",
    duration: "1d6 rounds + 1 round/level",
    school: ["black", "white"],
    components: "V,S,M",
    save: "negate",
    wizLvl: 5,
    priestLvl: 0,
  },
  "Hold Person": {
    cast: "1 action",
    range: "120'",
    target: "1 creature or 1 group within 20' radius",
    duration: "1d6 rounds + 1 round/level",
    school: ["black", "white"],
    components: "V,S,M",
    save: "negate",
    wizLvl: 3,
    priestLvl: 2,
  },
  "Holy Word (Unholy Word)": {
    cast: "1 action",
    range: "0'",
    target: "60' radius",
    duration: "special",
    school: null,
    components: "V",
    save: "special",
    wizLvl: 0,
    priestLvl: 7,
  },
  Hurricane: {
    cast: "1 action",
    range: "480'",
    target: "360' high, 360' wide, 160' deep",
    duration: "1 round/level",
    school: ["green"],
    components: "V,S",
    save: "special",
    wizLvl: 6,
    priestLvl: 6,
  },
  "Hypnotic Pattern": {
    cast: "1 action",
    range: "120'",
    target: "30' square",
    duration: "concentration",
    school: ["black", "blue"],
    components: "S",
    save: "negate",
    wizLvl: 2,
    priestLvl: 0,
  },
  "Insect Plague": {
    cast: "1 action",
    range: "240'",
    target: "36' radius, '6 high cloud",
    duration: "10 min/level",
    school: ["green"],
    components: "V,S,M",
    save: "special",
    wizLvl: 4,
    priestLvl: 5,
  },
  Invisibility: {
    cast: "1 action",
    range: "0'",
    target: "1 creature or object",
    duration: "special",
    school: ["blue"],
    components: "V,S,M",
    save: "none",
    wizLvl: 2,
    priestLvl: 0,
  },
  "Invisibility, 10' radius": {
    cast: "1 action",
    range: "0'",
    target: "10' radius",
    duration: "special",
    school: ["blue"],
    components: "V,S,M",
    save: "none",
    wizLvl: 3,
    priestLvl: 0,
  },
  "Legend Lore": {
    cast: "special",
    range: "0'",
    target: "special",
    duration: "permanent",
    school: ["white"],
    components: "V,S,M",
    save: "none",
    wizLvl: 6,
    priestLvl: 0,
  },
  Levitate: {
    cast: "1 action",
    range: "60'",
    target: "1 creature no larger than a horse",
    duration: "10 min/level",
    school: ["red"],
    components: "V,S,M",
    save: "negate",
    wizLvl: 2,
    priestLvl: 0,
  },
  "Light (Darkness)": {
    cast: "1 action",
    range: "90'",
    target: "special",
    duration: "1 hour +10 min/level",
    school: ["black", "red", "white"],
    components: "V,S",
    save: "negate",
    wizLvl: 1,
    priestLvl: 1,
  },
  "Lightning Bolt": {
    cast: "1 action",
    range: "120'",
    target: "special",
    duration: "instant",
    school: ["red"],
    components: "V,S,M",
    save: "half",
    wizLvl: 3,
    priestLvl: 0,
  },
  "Limited Wish": {
    cast: "1 action",
    range: "special",
    target: "special",
    duration: "special",
    school: ["black", "blue", "green", "red", "white"],
    components: "V",
    save: "special",
    wizLvl: 7,
    priestLvl: 0,
  },
  "Locate Object": {
    cast: "1 ",
    range: "60' + 12'/level",
    target: "special",
    duration: "5 min/level",
    school: ["white"],
    components: "V,S",
    save: "none",
    wizLvl: 2,
    priestLvl: 3,
  },
  "Magic Missile": {
    cast: "1 action",
    range: "240'",
    target: "one target per missile",
    duration: "instant",
    school: ["red"],
    components: "V,S",
    save: "none",
    wizLvl: 1,
    priestLvl: 0,
  },
  "Major Creation": {
    cast: "1 turn",
    range: "0'",
    target: "special",
    duration: "1 hour/level",
    school: ["blue", "white"],
    components: "V,S,M",
    save: "none",
    wizLvl: 5,
    priestLvl: 0,
  },
  "Mass Suggestion": {
    cast: "1 action",
    range: "120'",
    target: "1 creature/level",
    duration: "1 hour + 1 hour/level",
    school: ["blue"],
    components: "V,M",
    save: "negate",
    wizLvl: 6,
    priestLvl: 0,
  },
  "Minor Creation": {
    cast: "1 turn",
    range: "0'",
    target: "special",
    duration: "1 hour/level",
    school: ["blue", "white"],
    components: "V,S,M",
    save: "none",
    wizLvl: 3,
    priestLvl: 0,
  },
  "Mirage Arcana": {
    cast: "1 turn",
    range: "480'",
    target: "1 square mile",
    duration: "12 days",
    school: ["blue"],
    components: "V,S",
    save: "special",
    wizLvl: 7,
    priestLvl: 0,
  },
  "Mirror Image": {
    cast: "1 action",
    range: "0'",
    target: "20' radius",
    duration: "1 hour",
    school: ["blue"],
    components: "V,S",
    save: "none",
    wizLvl: 2,
    priestLvl: 0,
  },
  "Negate Invisibility": {
    cast: "1 action",
    range: "60' cone with 30' base",
    target: "special",
    duration: "1 hour",
    school: ["blue"],
    components: "V,S",
    save: "none",
    wizLvl: 2,
    priestLvl: 0,
  },
  "Neutralize Poison (Poison)": {
    cast: "1 action",
    range: "0'",
    target: "1 creature or 1 object",
    duration: "permanent",
    school: null,
    components: "V,S",
    save: "negate",
    wizLvl: 0,
    priestLvl: 4,
  },
  "Part Water": {
    cast: "1 action",
    range: "120'",
    target: "1 body of water",
    duration: "1 hour + 10 mins./level",
    school: ["green"],
    components: "V,S,M",
    save: "none",
    wizLvl: 6,
    priestLvl: 6,
  },
  "Pass Without Trace": {
    cast: "1 action",
    range: "0'",
    target: "3 creatures +1 per 2 levels",
    duration: "30 mins./level",
    school: ["green"],
    components: "V,S",
    save: "none",
    wizLvl: 1,
    priestLvl: 0,
  },
  Passwall: {
    cast: "1 action",
    range: "30'",
    target: "special",
    duration: "10 mins./level",
    school: ["green"],
    components: "V,S,M",
    save: "none",
    wizLvl: 5,
    priestLvl: 0,
  },
  "Phantasmal Force": {
    cast: "1 action",
    range: "120'",
    target: "",
    duration: "concentration",
    school: ["blue"],
    components: "V,S,M",
    save: "special",
    wizLvl: 2,
    priestLvl: 0,
  },
  "Phantom Image": {
    cast: "1 action",
    range: "60'",
    target: "30' cube",
    duration: "10 min",
    school: ["blue"],
    components: "V,S,M",
    save: "special",
    wizLvl: 1,
    priestLvl: 0,
  },
  "Plane Shift": {
    cast: "1 action",
    range: "0'",
    target: "Up to 7 creatures",
    duration: "permanent",
    school: ["black", "white"],
    components: "V,S,M",
    save: "special",
    wizLvl: 0,
    priestLvl: 5,
  },
  "Polymorph Other": {
    cast: "1 action",
    range: "120'",
    target: "1 creature",
    duration: "permanent",
    school: ["black", "blue"],
    components: "V,S,M",
    save: "negate",
    wizLvl: 4,
    priestLvl: 0,
  },
  "Polymorph Self": {
    cast: "1 action",
    range: "0'",
    target: "caster",
    duration: "1 hour + 10 min/level",
    school: ["blue", "green"],
    components: "V",
    save: "none",
    wizLvl: 4,
    priestLvl: 0,
  },
  "Projected Image": {
    cast: "1 action",
    range: "240'",
    target: "special",
    duration: "1 hour",
    school: ["blue"],
    components: "V,S",
    save: "none",
    wizLvl: 5,
    priestLvl: 0,
  },
  "Protection From Normal Missiles": {
    cast: "1 action",
    range: "0'",
    target: "caster",
    duration: "2 rounds/level",
    school: ["white"],
    components: "V,S",
    save: "none",
    wizLvl: 3,
    priestLvl: 0,
  },
  "Purify Food & Water (Putrify Food & Water)": {
    cast: "1 action",
    range: "30'",
    target: "special",
    duration: "permanent",
    school: null,
    components: "V,S,M",
    save: "none",
    wizLvl: 0,
    priestLvl: 1,
  },
  Pyrotechnics: {
    cast: "1 action",
    range: "60'",
    target: "10 or 100 times fire source",
    duration: "special",
    school: ["red"],
    components: "V,S",
    save: "none",
    wizLvl: 2,
    priestLvl: 0,
  },
  "Quest (Remove Quest)": {
    cast: "1 action",
    range: "60'",
    target: "1 creature",
    duration: "special",
    school: null,
    components: "V,S",
    save: "none",
    wizLvl: 0,
    priestLvl: 5,
  },
  "Raise Dead (Finger of Death)": {
    cast: "1 action",
    range: "30'",
    target: "1 creature",
    duration: "permanent",
    school: null,
    components: "V,S",
    save: "special",
    wizLvl: 0,
    priestLvl: 5,
  },
  "Ray of Enfeeblement": {
    cast: "1 action",
    range: "60'",
    target: "1 creature",
    duration: "1 round/level",
    school: ["black"],
    components: "V,S",
    save: "negate",
    wizLvl: 2,
    priestLvl: 0,
  },
  Regenerate: {
    cast: "1 action",
    range: "0'",
    target: "1 creature",
    duration: "permanent",
    school: null,
    components: "V,S,M",
    save: "none",
    wizLvl: 0,
    priestLvl: 7,
  },
  Reincarnation: {
    cast: "1 turn",
    range: "0'",
    target: "1 creature",
    duration: "permanent",
    school: ["green", "white"],
    components: "V,S",
    save: "none",
    wizLvl: 4,
    priestLvl: 0,
  },
  "Remove Curse (Curse)": {
    cast: "1 turn",
    range: "0'",
    target: "1 creature, place, or object",
    duration: "permanent",
    school: ["black", "white"],
    components: "V,S",
    save: "none",
    wizLvl: 4,
    priestLvl: 3,
  },
  "Remove Fear (Cause Fear)": {
    cast: "1 action",
    range: "0'",
    target: "1 creature",
    duration: "permanent or 1 min/level",
    school: null,
    components: "V,S,M",
    save: "none/neg.",
    wizLvl: 0,
    priestLvl: 1,
  },
  Repulsion: {
    cast: "1 action",
    range: "0'",
    target: "special",
    duration: "1 round/level",
    school: ["red", "white"],
    components: "V,S,M",
    save: "none",
    wizLvl: 4,
    priestLvl: 0,
  },
  "Resist Cold": {
    cast: "1 action",
    range: "0'",
    target: "1 creature",
    duration: "10 mins./level",
    school: ["green", "white"],
    components: "V,S,M",
    save: "none",
    wizLvl: 1,
    priestLvl: 1,
  },
  "Resist Fire": {
    cast: "1 action",
    range: "0'",
    target: "1 creature",
    duration: "10 mins./level",
    school: ["white", "red"],
    components: "V,S,M",
    save: "none",
    wizLvl: 2,
    priestLvl: 2,
  },
  "Resurrection (Annihilation)": {
    cast: "1 turn",
    range: "0'",
    target: "1 creature",
    duration: "permanent",
    school: null,
    components: "V,S,M",
    save: "none",
    wizLvl: 0,
    priestLvl: 7,
  },
  Rust: {
    cast: "1 action",
    range: "120'",
    target: "special",
    duration: "permanent",
    school: ["green", "red"],
    components: "V,S,M",
    save: "negate",
    wizLvl: 5,
    priestLvl: 0,
  },
  Scrying: {
    cast: "1 min",
    range: "0'",
    target: "special",
    duration: "special",
    school: ["white"],
    components: "V,S,M",
    save: "none",
    wizLvl: 5,
    priestLvl: 0,
  },
  Shades: {
    cast: "1 action",
    range: "120'",
    target: "20' cube",
    duration: "1 round/level",
    school: ["black", "blue"],
    components: "V,S",
    save: "special",
    wizLvl: 6,
    priestLvl: 0,
  },
  "Shadow Monsters": {
    cast: "1 action",
    range: "120'",
    target: "20' cube",
    duration: "1 round/level",
    school: ["black", "blue"],
    components: "V,S",
    save: "special",
    wizLvl: 3,
    priestLvl: 0,
  },
  Shield: {
    cast: "1 action",
    range: "0'",
    target: "caster",
    duration: "30 min",
    school: ["white"],
    components: "V,S",
    save: "none",
    wizLvl: 1,
    priestLvl: 0,
  },
  "Silence 15' Radius": {
    cast: "1 action",
    range: "120'",
    target: "15' radius",
    duration: "2 rounds/level",
    school: null,
    components: "V,S",
    save: "special",
    wizLvl: 0,
    priestLvl: 2,
  },
  Simulacrum: {
    cast: "8 hours",
    range: "0'",
    target: "1 creature",
    duration: "permanent",
    school: ["black", "blue"],
    components: "V,S,M",
    save: "none",
    wizLvl: 7,
    priestLvl: 0,
  },
  Sleep: {
    cast: "1 action",
    range: "90'",
    target: "special",
    duration: "10 min/level",
    school: ["black"],
    components: "V,S,M",
    save: "none",
    wizLvl: 1,
    priestLvl: 0,
  },
  "Slow Spell": {
    cast: "1 action",
    range: "240'",
    target: "1 creature/level within 20' radius",
    duration: "3 rounds + 1 round/level",
    school: ["black"],
    components: "V,S,M",
    save: "negate",
    wizLvl: 3,
    priestLvl: 0,
  },
  "Speak with Animals": {
    cast: "1 action",
    range: "60'",
    target: "caster",
    duration: "10 min",
    school: ["green"],
    components: "V,S",
    save: "none",
    wizLvl: 1,
    priestLvl: 2,
  },
  "Speak with Plants": {
    cast: "1 action",
    range: "0'",
    target: "caster",
    duration: "10 min",
    school: ["green"],
    components: "V,S",
    save: "none",
    wizLvl: 2,
    priestLvl: 4,
  },
  "Spell Fork": {
    cast: "Instant",
    range: "240'",
    target: "1 ranged spell",
    duration: "special",
    school: ["red"],
    components: "V,S",
    save: "special",
    wizLvl: 6,
    priestLvl: 0,
  },
  "Spider Climb": {
    cast: "1 action",
    range: "0'",
    target: "1 creature",
    duration: "1 hour",
    school: ["green"],
    components: "V,S,M",
    save: "none",
    wizLvl: 1,
    priestLvl: 0,
  },
  "Sticks to Snakes": {
    cast: "1 action",
    range: "30'",
    target: "30' diameter",
    duration: "2 rounds/level",
    school: ["green"],
    components: "V,S,M",
    save: "none",
    wizLvl: 3,
    priestLvl: 6,
  },
  "Stinking Cloud": {
    cast: "1 action",
    range: "30'",
    target: "20' diameter cloud",
    duration: "1 round/level",
    school: ["green", "red"],
    components: "V,S,M",
    save: "special",
    wizLvl: 2,
    priestLvl: 0,
  },
  Suggestion: {
    cast: "1 action",
    range: "120'",
    target: "1 creature",
    duration: "1 hour + 1 hour/level",
    school: ["blue"],
    components: "V,M",
    save: "negate",
    wizLvl: 3,
    priestLvl: 0,
  },
  "Suspend Life": {
    cast: "1 action",
    range: "0'",
    target: "1 creature",
    duration: "10 years/level",
    school: ["black"],
    components: "V,S",
    save: "negate",
    wizLvl: 5,
    priestLvl: 5,
  },
  Symbol: {
    cast: "1 action",
    range: "0'",
    target: "special",
    duration: "10 min/level",
    school: null,
    components: "V,S,M",
    save: "none",
    wizLvl: 0,
    priestLvl: 7,
  },
  Telekinesis: {
    cast: "1 action",
    range: "120'",
    target: "special",
    duration: "2 rounds/level",
    school: ["red"],
    components: "V,S",
    save: "none",
    wizLvl: 5,
    priestLvl: 0,
  },
  Teleport: {
    cast: "1 action",
    range: "0'",
    target: "caster and 1 other creature per 4 levels",
    duration: "instant",
    school: ["red"],
    components: "V",
    save: "negate",
    wizLvl: 5,
    priestLvl: 0,
  },
  "Tongues (Gibberish)": {
    cast: "1 action",
    range: "0'",
    target: "60' radius",
    duration: "10 min",
    school: null,
    components: "V,S",
    save: "none",
    wizLvl: 0,
    priestLvl: 4,
  },
  "True Seeing": {
    cast: "1 action",
    range: "0'",
    target: "caster",
    duration: "10 min/level",
    school: ["white"],
    components: "V,S,M",
    save: "none",
    wizLvl: 6,
    priestLvl: 5,
  },
  "Vampiric Grasp": {
    cast: "1 action",
    range: "0'",
    target: "1 creature",
    duration: "instant",
    school: ["black"],
    components: "V,S",
    save: "none",
    wizLvl: 2,
    priestLvl: 0,
  },
  "Vampiric Transfusion": {
    cast: "1 min",
    range: "0'",
    target: "2 living creatures",
    duration: "permanent",
    school: ["black"],
    components: "V,S",
    save: "negate",
    wizLvl: 4,
    priestLvl: 0,
  },
  Veil: {
    cast: "1 action",
    range: "120'",
    target: "20' x 20'/level",
    duration: "10 min/level",
    school: ["blue"],
    components: "V,S",
    save: "none",
    wizLvl: 5,
    priestLvl: 0,
  },
  Visage: {
    cast: "1 action",
    range: "90'",
    target: "1 creature",
    duration: "1 hour",
    school: ["blue"],
    components: "V,S",
    save: "negate",
    wizLvl: 1,
    priestLvl: 0,
  },
  Vision: {
    cast: "1 hour",
    range: "0'",
    target: "caster",
    duration: "1 min",
    school: ["white"],
    components: "V,S,M",
    save: "none",
    wizLvl: 7,
    priestLvl: 0,
  },
  "Wall of Fire": {
    cast: "1 action",
    range: "120'",
    target: "",
    duration: "concenteration or 1 round/level",
    school: ["red"],
    components: "V,S",
    save: "none",
    wizLvl: 4,
    priestLvl: 0,
  },
  "Wall of Force": {
    cast: "1 action",
    range: "30'",
    target: "special",
    duration: "10 min + 1 min/level",
    school: ["red", "white"],
    components: "V,S,M",
    save: "none",
    wizLvl: 5,
    priestLvl: 0,
  },
  "Wall of Ice": {
    cast: "1 action",
    range: "120'",
    target: "special",
    duration: "10 min/level",
    school: ["green"],
    components: "V,S,M",
    save: "none",
    wizLvl: 4,
    priestLvl: 0,
  },
  "Wall of Thorns": {
    cast: "1 action",
    range: "120'",
    target: "special",
    duration: "10 min/level",
    school: ["green"],
    components: "V,S,M",
    save: "none",
    wizLvl: 5,
    priestLvl: 0,
  },
  "Water Breathing": {
    cast: "1 action",
    range: "60'",
    target: "2 creatures/level",
    duration: "1 hour/level",
    school: ["green"],
    components: "S,M",
    save: "none",
    wizLvl: 3,
    priestLvl: 0,
  },
  Web: {
    cast: "1 action",
    range: "60'",
    target: "21' cube",
    duration: "1 hour",
    school: ["green"],
    components: "V,S,M",
    save: "negate",
    wizLvl: 2,
    priestLvl: 0,
  },
  "Wizard Eye": {
    cast: "1 min",
    range: "240'",
    target: "special",
    duration: "1 hour",
    school: ["white"],
    components: "V,S,M",
    save: "none",
    wizLvl: 4,
    priestLvl: 0,
  },
  "Wizard Lock (Wizard Knock)": {
    cast: "1 action",
    range: "0'",
    target: "1 door, chest, gate, etc.",
    duration: "Permanent",
    school: ["black", "white"],
    components: "V,S",
    save: "none",
    wizLvl: 2,
    priestLvl: 0,
  },
  "Word of Recall": {
    cast: "1 action",
    range: "0'",
    target: "special",
    duration: "instant",
    school: null,
    components: "V",
    save: "none",
    wizLvl: 0,
    priestLvl: 6,
  },
};
