const dataDisads = [
  {
    "id": 0,
    "name": "none",
    "desc": "none"
  },
  {
    "id": 1,
    "name": "Disfigured",
    "desc": "The character is noticeably disfigured and will suffer a -3 to all reaction rolls. Furthermore, they will be easily known,recognized, and remembered by their disfigurement (I'm searching for a one-armed man…)."
  },
  {
    "id": 2,
    "name": "Uneducated",
    "desc": "The character is uneducated and uncultured. He can not read or write, and has only the most rudimentary grasp of science, culture, history, art, etc. He suffers -3 to all reaction or Charisma attribute rolls in settings where education and refinement are important (in a king's court, at formal ball, addressing a powerful wizard, etc.). \n\nHe will be impressed by commonplace objects (like clocks) and the GM may require he make a wisdom roll to understand them. Finally, he will be easily deceived by more educated characters making false claims on learned topics."
  },
  {
    "id": 3,
    "name": "Lowered Attribute(s)",
    "desc": "The character can reduce three points from their attributes. This can be all from one or spread across several. No attribute may be reduced below 3 or a racial minimum."
  },
  {
    "id": 4,
    "name": "Lame",
    "desc": "Character is lame  and suffers -3\" of movement, and suffers Disadvantage on rolls  where one's legs are important (jumping, climbing, etc.). Certain acts, like tightrope walking, will be impossible for them.  In combat, movement times are increased by +1 rounds for traveling medium or higher ranges."
  },
  {
    "id": 5,
    "name": "Missing an Arm or Hand",
    "desc": "Character is unable to use two handed weapons and suffers Disadvantage on rolls for actions that would require two hands (like climbing)."
  },
  {
    "id": 6,
    "name": "Weak Ears",
    "desc": "The character has poor hearing and will suffer Disadvantage to all perception rolls involving sound. They will also be unable to hear faint sounds or clearly make out  conversations in noisy environments  without an auditory aid like an ear trumpet."
  },
  {
    "id": 7,
    "name": "Weak Eyes",
    "desc": "The character has poor vision and will suffer Disadvantage to all perception rolls involving sight. They will also be unable to read or discern fine details without a visual aid (glasses, monocle, magnifying glass, etc.)."
  },
  {
    "id": 8,
    "name": "Vow of Chivalry",
    "desc": "The character must adhere to a strict code of honor and chivalry. They will never lie, cheat, or go back on their word. They must always engage in fair play, especially in combat. This means avoiding ranged or surprise attacks, or leveraging  any situation that would unfairly advantage them against their foes. The character must always and everywhere be right and good against evil and injustice. They must be generous and never withhold help from those in need."
  },
  {
    "id": 9,
    "name": "Vow of Modesty",
    "desc": "The character must live modestly They may only retain items they can personally use and carry, and only keep enough wealth for basic living. All else must be donated to worthy causes (not other player characters or their henchmen)."
  },
  {
    "id": 10,
    "name": "Vow of Nature",
    "desc": "copy"
  }
];


export { dataDisads }