const mutationDefectsData = [
  {
    id: 1,
    wt: 3,
    roll: "1-9",
    name: "Allergy",
    desc: "description",
  },

  {
    id: 2,
    wt: 12,
    roll: "10-12",
    name: "Allurement Odor",
    desc: "description",
  },

  {
    id: 3,
    wt: 15,
    roll: "13-15",
    name: "Amnesia, Memory Limit",
    desc: "description",
  },

  {
    id: 4,
    wt: 18,
    roll: "16-18",
    name: "Amnesia, Periodic",
    desc: "description",
  },

  {
    id: 5,
    wt: 21,
    roll: "19-21",
    name: "Antagonistic Dual Brain",
    desc: "description",
  },

  {
    id: 6,
    wt: 24,
    roll: "22-24",
    name: "Dependence, light",
    desc: "description",
  },

  {
    id: 7,
    wt: 27,
    roll: "25-27",
    name: "Dependence, water",
    desc: "description",
  },

  {
    id: 8,
    wt: 30,
    roll: "28-30",
    name: "Frailty",
    desc: "description",
  },

  {
    id: 9,
    wt: 33,
    roll: "31-33",
    name: "Hyper Metabolism",
    desc: "description",
  },

  {
    id: 10,
    wt: 36,
    roll: "34-36",
    name: "Impaired Attribute, Constitution",
    desc: "description",
  },

  {
    id: 11,
    wt: 39,
    roll: "37-39",
    name: "Impaired Attribute, Dexterity",
    desc: "description",
  },

  {
    id: 12,
    wt: 42,
    roll: "40-42",
    name: "Impaired Attribute, Strength",
    desc: "description",
  },

  {
    id: 13,
    wt: 45,
    roll: "43-45",
    name: "Impaired Mobility",
    desc: "description",
  },

  {
    id: 14,
    wt: 48,
    roll: "46-48",
    name: "Impaired Sense, Hearing",
    desc: "description",
  },

  {
    id: 15,
    wt: 51,
    roll: "49-51",
    name: "Impaired Sense, Photophobia",
    desc: "description",
  },

  {
    id: 16,
    wt: 54,
    roll: "52-54",
    name: "Impaired Sense, Smell",
    desc: "description",
  },

  {
    id: 17,
    wt: 57,
    roll: "55-57",
    name: "Impaired Sense, Touch",
    desc: "description",
  },

  {
    id: 18,
    wt: 60,
    roll: "58-60",
    name: "Impaired Sense, Vision",
    desc: "description",
  },

  {
    id: 19,
    wt: 63,
    roll: "61-63",
    name: "Insanity, Berserkism",
    desc: "description",
  },

  {
    id: 20,
    wt: 66,
    roll: "64-66",
    name: "Insanity, Mental Block",
    desc: "description",
  },

  {
    id: 21,
    wt: 69,
    roll: "67-69",
    name: "Insanity, Phobia",
    desc: "description",
  },

  {
    id: 22,
    wt: 72,
    roll: "70-72",
    name: "Monstrous Appearance",
    desc: "description",
  },

  {
    id: 23,
    wt: 75,
    roll: "73-75",
    name: "Reduced Endurance",
    desc: "description",
  },

  {
    id: 24,
    wt: 78,
    roll: "76-93",
    name: "Vulnerability",
    desc: "description",
  },

  {
    id: 25,
    wt: 96,
    roll: "94-96",
    name: "Weak Willed",
    desc: "description",
  },
];

export { mutationDefectsData };
