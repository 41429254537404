const presetData = [
  {
    id: 0,
    name: "Fighter",
    aspect: "fighter",
    subclass: false,
    desc: "The standard fighter is a straight-ahead, no-frills combatant. Their ranks are filled with knights, soldiers, gladiators, and the like.",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Multi-Attack",
      talentLevel1: "Combat Specialization",
      talentKnave1: "choose",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Durability",
      talentLevel5: "Medical",
      talentLevel7: "Attribute Increase",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 1,
    name: "Barbarian (mountains)",
    aspect: "fighter",
    subclass: true,
    desc: "copy",
    disad1: "Uneducated",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Multi-Attack",
      talentLevel1: "Durability",
      talentKnave1: "choose",
      talentDisad1: "Survival & Tracking",
      talentDisad2: "choose",
      talentLevel3: "Alertness",
      talentLevel5: "Climbing",
      talentLevel7: "Stealth",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 2,
    name: "Barbarian (plains)",
    aspect: "fighter",
    subclass: true,
    desc: "copy",
    disad1: "Uneducated",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Multi-Attack",
      talentLevel1: "Durability",
      talentKnave1: "choose",
      talentDisad1: "Survival & Tracking",
      talentDisad2: "choose",
      talentLevel3: "Riding",
      talentLevel5: "Medical",
      talentLevel7: "Combat Specialization",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 3,
    name: "Berserker",
    aspect: "fighter",
    subclass: true,
    desc: "copy",
    disad1: "Uneducated",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Multi-Attack",
      talentLevel1: "Berserk",
      talentKnave1: "choose",
      talentDisad1: "Durability",
      talentDisad2: "choose",
      talentLevel3: "Attribute Increase",
      talentLevel5: "Medical",
      talentLevel7: "Alertness",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 4,
    name: "Paladin",
    aspect: "fighter",
    subclass: true,
    desc: "copy",
    disad1: "Vow of Chivalry",
    disad2: "Vow of Modesty",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Multi-Attack",
      talentLevel1: "Durability",
      talentKnave1: "choose",
      talentDisad1: "Holy Aura",
      talentDisad2: "Thaumaturgy",
      talentLevel3: "Bravery",
      talentLevel5: "Combat Specialization",
      talentLevel7: "Turn Undead",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 5,
    name: "Ranger",
    aspect: "fighter",
    subclass: true,
    desc: "copy",
    disad1: "Vow of Modesty",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Multi-Attack",
      talentLevel1: "Combat Specialization",
      talentKnave1: "choose",
      talentDisad1: "Survival & Tracking",
      talentDisad2: "choose",
      talentLevel3: "Stealth",
      talentLevel5: "Alertness",
      talentLevel7: "Wizardry 1",
      talentLevel9: "Missile Deflection",
    },
  },
  {
    id: 6,
    name: "Swashbuckler",
    aspect: "fighter",
    subclass: true,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Multi-Attack",
      talentLevel1: "Stealth",
      talentKnave1: "choose",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Acrobatics",
      talentLevel5: "Climbing",
      talentLevel7: "Combat Specialization",
      talentLevel9: "Missile Deflection",
    },
  },
  {
    id: 7,
    name: "Cleric",
    aspect: "priest",
    subclass: false,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Thaumaturgy",
      talentLevel1: "Turn Undead",
      talentKnave1: "choose",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Attribute Increase",
      talentLevel5: "Scholarly Knowledge",
      talentLevel7: "Attribute Increase",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 8,
    name: "Cultist",
    aspect: "priest",
    subclass: true,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Thaumaturgy",
      talentLevel1: "Sermonize",
      talentKnave1: "choose",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Divine Attunement",
      talentLevel5: "Divine Shapeshift",
      talentLevel7: "Wizardry 1",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 9,
    name: "Mystic",
    aspect: "priest",
    subclass: true,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Thaumaturgy",
      talentLevel1: "Scholarly Knowledge",
      talentKnave1: "choose",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Psychic Sensitivity",
      talentLevel5: "Arcane Knowledge",
      talentLevel7: "Arcane Sensitivity",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 10,
    name: "Monk",
    aspect: "priest",
    subclass: true,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Thaumaturgy",
      talentLevel1: "Medical",
      talentKnave1: "choose",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Scholarly Knowledge",
      talentLevel5: "Turn Undead",
      talentLevel7: "Bestow Blessing",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 11,
    name: "Templar",
    aspect: "priest",
    subclass: true,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Thaumaturgy",
      talentLevel1: "Durability",
      talentKnave1: "choose",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Multi-Attack",
      talentLevel5: "Medical",
      talentLevel7: "Turn Undead",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 12,
    name: "Magic-User",
    aspect: "wizard",
    subclass: false,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Wizardry 1",
      talentLevel1: "Wizardry 2",
      talentKnave1: "choose",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Wizardry 3",
      talentLevel5: "Arcane Knowledge",
      talentLevel7: "Spell Refashionment",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 13,
    name: "Druid",
    aspect: "wizard",
    subclass: true,
    desc: "copy",
    disad1: "Vow of Nature",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Wizardry 1",
      talentLevel1: "Thaumaturgy",
      talentKnave1: "choose",
      talentDisad1: "Survival & Tracking",
      talentDisad2: "choose",
      talentLevel3: "Divine Attunement",
      talentLevel5: "Divine Shapeshift",
      talentLevel7: "Wizardry 2",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 14,
    name: "Hermit/Witch",
    aspect: "wizard",
    subclass: true,
    desc: "Copy",
    disad1: "Disfigured",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Wizardry 1",
      talentLevel1: "Survival & Tracking",
      talentKnave1: "choose",
      talentDisad1: "Arcane Knowledge",
      talentDisad2: "choose",
      talentLevel3: "Divine Attunement",
      talentLevel5: "Wizardry 2",
      talentLevel7: "Medical",
      talentLevel9: "Wizardry 3",
    },
  },
  {
    id: 15,
    name: "Martial Mage",
    aspect: "wizard",
    subclass: true,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Wizardry 1",
      talentLevel1: "Durability",
      talentKnave1: "choose",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Battle Casting",
      talentLevel5: "Medical",
      talentLevel7: "Wizardry 2",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 16,
    name: "Enchantress",
    aspect: "wizard",
    subclass: true,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Wizardry 1",
      talentLevel1: "Beguilement",
      talentKnave1: "choose",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Wizardry 2",
      talentLevel5: "Inspiration",
      talentLevel7: "Arcane Knowledge",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 17,
    name: "Illusionist",
    aspect: "wizard",
    subclass: true,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Wizardry 1",
      talentLevel1: "Stealth Casting",
      talentKnave1: "choose",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Stealth",
      talentLevel5: "Arcane Knowledge",
      talentLevel7: "Wizardry 2",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 18,
    name: "Necromancer",
    aspect: "wizard",
    subclass: true,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Wizardry 1",
      talentLevel1: "Medical",
      talentKnave1: "choose",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Thaumaturgy",
      talentLevel5: "Wizardry 2",
      talentLevel7: "Arcane Knowledge",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 19,
    name: "Sage",
    aspect: "wizard",
    subclass: true,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Wizardry 1",
      talentLevel1: "Arcane Knowledge",
      talentKnave1: "choose",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Scholarly Knowledge",
      talentLevel5: "Arcane Sensitivity",
      talentLevel7: "Psychic Sensitivity",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 20,
    name: "Sorcerer",
    aspect: "wizard",
    subclass: true,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Wizardry 1",
      talentLevel1: "Arcane Sensitivity",
      talentKnave1: "choose",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Psychic Sensitivity",
      talentLevel5: "Wizardry 2",
      talentLevel7: "Alertness",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 21,
    name: "Knave",
    aspect: "knave",
    subclass: false,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Stealth",
      talentLevel1: "Burglary",
      talentKnave1: "Backstabbing",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Sleight of hand",
      talentLevel5: "Fraud",
      talentLevel7: "Lore & Read Magic",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 22,
    name: "Assasin",
    aspect: "knave",
    subclass: true,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Stealth",
      talentLevel1: "Assassination",
      talentKnave1: "Disguise",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Backstabbing",
      talentLevel5: "Burglary",
      talentLevel7: "Fraud",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 23,
    name: "Acrobat",
    aspect: "knave",
    subclass: true,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Stealth",
      talentLevel1: "Acrobatics",
      talentKnave1: "Burglary",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Climbing",
      talentLevel5: "Escapology",
      talentLevel7: "Lore & Read Magic",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 24,
    name: "Bard/Jester/Lark",
    aspect: "knave",
    subclass: true,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Stealth",
      talentLevel1: "Beguilement",
      talentKnave1: "Inspiration",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Burglary",
      talentLevel5: "Lore & Read Magic",
      talentLevel7: "Wizardry 1",
      talentLevel9: "Combat Specialization",
    },
  },
  {
    id: 25,
    name: "Beggar",
    aspect: "knave",
    subclass: true,
    desc: "copy",
    disad1: "Disfigured",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Stealth",
      talentLevel1: "Sleight of hand",
      talentKnave1: "Backstabbing",
      talentDisad1: "Attribute Increase",
      talentDisad2: "choose",
      talentLevel3: "Burglary",
      talentLevel5: "Disguise",
      talentLevel7: "Fraud",
      talentLevel9: "Attribute Increase",
    },
  },
  {
    id: 26,
    name: "Mountebank",
    aspect: "knave",
    subclass: true,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Stealth",
      talentLevel1: "Sleight of hand",
      talentKnave1: "Backstabbing",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Wizardry 1",
      talentLevel5: "Disguise",
      talentLevel7: "Fraud",
      talentLevel9: "Escapology",
    },
  },
  {
    id: 27,
    name: "Dwarven Fighter/Thief",
    aspect: "fighter",
    subclass: false,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Multi-Attack",
      talentLevel1: "Dwarf",
      talentKnave1: "Burglary",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Stealth",
      talentLevel5: "Combat Specialization",
      talentLevel7: "Climbing",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 28,
    name: "Elven Figter/Magic-User/Thief",
    aspect: "knave",
    subclass: false,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Stealth",
      talentLevel1: "Elf",
      talentKnave1: "Burglary",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Wizardry 1",
      talentLevel5: "Combat Specialization",
      talentLevel7: "Wizardry 2",
      talentLevel9: "Stronghold",
    },
  },
  {
    id: 29,
    name: "Gnome Illusionist",
    aspect: "wizard",
    subclass: false,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Wizardry 1",
      talentLevel1: "Gnome",
      talentKnave1: "Wizardry 1",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Stealth",
      talentLevel5: "Stealth Casting",
      talentLevel7: "Wizardry 2",
      talentLevel9: "Escapology",
    },
  },
  {
    id: 30,
    name: "Half-Orc Fighter",
    aspect: "fighter",
    subclass: false,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Multi-Attack",
      talentLevel1: "Half-Orc",
      talentKnave1: "choose",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Climbing",
      talentLevel5: "Durability",
      talentLevel7: "Riding",
      talentLevel9: "Attribute Increase",
    },
  },
  {
    id: 31,
    name: "Halfling Thief",
    aspect: "knave",
    subclass: false,
    desc: "copy",
    disad1: "none",
    disad2: "none",
    talents: {
      talentAssigned1: "Combat",
      talentAssigned2: "Stealth",
      talentLevel1: "Halfling",
      talentKnave1: "Backstabbing",
      talentDisad1: "choose",
      talentDisad2: "choose",
      talentLevel3: "Burglary",
      talentLevel5: "Climbing",
      talentLevel7: "Sleight of hand",
      talentLevel9: "Lore & Read Magic",
    },
  },
];

export { presetData };
