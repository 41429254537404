const dataAttributes = {
	strength:   { 
		'name' : 'STR',
		'roll'  : 10,
		'min' : 3,
		'max' : 18,
		'mod' : 0,
		'total' : 10,
		'bonus': 0,
	},
	dexterity:  { 
		'name' : 'DEX',
		'roll'  : 10,
		'min' : 3,
		'max' : 18,
		'mod' : 0,
		'total' : 10,
		'bonus': 0,
	},
	constitution:   { 
		'name' : 'CON',
		'roll'  : 10,
		'min' : 3,
		'max' : 18,
		'mod' : 0,
		'total' : 10,
		'bonus': 0,
	},
	intelligence:  { 
		'name' : 'INT',
		'roll'  : 10,
		'min' : 3,
		'max' : 18,
		'mod' : 0,
		'total' : 10,
		'bonus': 0,
	},
	wisdom: { 
		'name' : 'WIS',
		'roll'  : 10,
		'min' : 3,
		'max' : 18,
		'mod' : 0,
		'total' : 10,
		'bonus': 0,
	},
	charisma: { 
		'name' : 'CHA',
		'roll'  : 10,
		'min' : 3,
		'max' : 18,
		'mod' : 0,
		'total' : 10,
		'bonus': 0,
	}
};

export {
	dataAttributes
}