const shieldData = [
  {
    name: "none",
    modifier: 0,
  },
  {
    name: "Small",
    modifier: 1,
  },
  {
    name: "Large",
    modifier: 2,
  },
];

export { shieldData };
