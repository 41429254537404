const psionicsData = [
  {
    id: 1,
    wt: 3,
    cumPerc: 3,
    roll: "1-3",
    name: "Aura Adjustment",
    discipline: "Aura",
    psp: 2,
    initiate: "1 round",
    duration: "1 hour",
    range: "0'",
    visibility: "invisible",
    save: "none",
    target: "1 creature, object, or area up to 30' x 30'",
    rangeInf: false,
  },
  {
    id: 2,
    wt: 5,
    cumPerc: 8,
    roll: "4-8",
    name: "Aura Reading",
    discipline: "Aura",
    psp: 1,
    initiate: "1 min.",
    duration: "instant",
    range: "30'",
    visibility: "invisible",
    save: "none",
    target: "1 creature, object, or area up to 30' x 30'",
    rangeInf: false,
  },
  {
    id: 3,
    wt: 3,
    cumPerc: 11,
    roll: "9-11",
    name: "Force of Will",
    discipline: "Aura",
    psp: "special",
    initiate: "1 action",
    duration: "1 round",
    range: "0'",
    visibility: "invisible",
    save: "none",
    target: "user",
    rangeInf: false,
  },
  {
    id: 4,
    wt: 4,
    cumPerc: 15,
    roll: "12-15",
    name: "Psychic Healing",
    discipline: "Aura",
    psp: "3 (stacking)",
    initiate: "1 action",
    duration: "instant",
    range: "0'",
    visibility: "visible",
    save: "none",
    target: "1 creature",
    rangeInf: false,
  },
  {
    id: 5,
    wt: 2,
    cumPerc: 17,
    roll: "16-17",
    name: "Psychic Lightning",
    discipline: "Aura",
    psp: "3 (stacking)",
    initiate: "1 action",
    duration: "instant",
    range: "60'",
    visibility: "visible",
    save: "half",
    target: "1 creature with an aura",
    rangeInf: false,
  },
  {
    id: 6,
    WT: 3,
    cumPerc: 20,
    roll: "18-20",
    name: "Psychic Surgery",
    discipline: "Aura",
    psp: "special",
    initiate: "1 turn",
    duration: "instant",
    range: "0'",
    visibility: "visible",
    save: "none",
    target: "1 creature",
    rangeInf: false,
  },
  {
    id: 7,
    WT: 2,
    cumPerc: 22,
    roll: "21-22",
    name: "Tower of Iron Will",
    discipline: "Aura",
    psp: 5,
    initiate: "Instant",
    duration: "10 minutes",
    range: "0'",
    visibility: "visible",
    save: "none",
    target: "10' radius",
    rangeInf: false,
  },
  {
    id: 8,
    WT: 5,
    cumPerc: 27,
    roll: "23-27",
    name: "Clairvoyance",
    discipline: "Omniscience",
    psp: 2,
    initiate: "1 round",
    duration: "10 minutes",
    range: "480'",
    visibility: "invisible",
    save: "none",
    target: "special",
    rangeInf: false,
  },
  {
    id: 9,
    WT: 2,
    cumPerc: 29,
    roll: "28-29",
    name: "Fate Avoidance",
    discipline: "Omniscience",
    psp: 10,
    initiate: "Instant",
    duration: "instant",
    range: "0'",
    visibility: "invisible",
    save: "none",
    target: "user",
    rangeInf: false,
  },
  {
    id: 10,
    WT: 3,
    cumPerc: 32,
    roll: "30-32",
    name: "Locate Object",
    discipline: "Omniscience",
    psp: 5,
    initiate: "1 min.",
    duration: "instant",
    range: "120'",
    visibility: "invisible",
    save: "none",
    target: "user",
    rangeInf: false,
  },
  {
    id: 11,
    WT: 3,
    cumPerc: 35,
    roll: "33-35",
    name: "Mind Stalking",
    discipline: "Omniscience",
    psp: 5,
    initiate: "1 min.",
    duration: "special",
    range: "0'",
    visibility: "invisible",
    save: "special",
    target: "1 creature with an aura",
    rangeInf: false,
  },
  {
    id: 12,
    WT: 3,
    cumPerc: 38,
    roll: "36-38",
    name: "Precognition",
    discipline: "Omniscience",
    psp: 2,
    initiate: "5 min.",
    duration: "instant",
    range: "0'",
    visibility: "invisible",
    save: "none",
    target: "user",
    rangeInf: false,
  },
  {
    id: 13,
    WT: 2,
    cumPerc: 40,
    roll: "39-40",
    name: "Probability Manipulation",
    discipline: "Omniscience",
    psp: 5,
    initiate: "Instant",
    duration: "instant",
    range: "240'",
    visibility: "invisible",
    save: "none",
    target: "special",
    rangeInf: false,
  },
  {
    id: 14,
    WT: 3,
    cumPerc: 43,
    roll: "41-43",
    name: "Psi Pathing",
    discipline: "Omniscience",
    psp: 5,
    initiate: "1 min.",
    duration: "special",
    range: "0'",
    visibility: "invisible",
    save: "none",
    target: "special",
    rangeInf: false,
  },
  {
    id: 15,
    WT: 2,
    cumPerc: 45,
    roll: "44-45",
    name: "Remote Viewing",
    discipline: "Omniscience",
    psp: 5,
    initiate: "1 round",
    duration: "special",
    range: "Special",
    visibility: "invisible",
    save: "special",
    target: "1 creature with an aura",
    rangeInf: false,
  },
  {
    id: 16,
    wt: 2,
    cumPerc: 47,
    roll: "46-47",
    name: "Vision",
    discipline: "Omniscience",
    psp: 10,
    initiate: "1 hour",
    duration: "instant",
    range: "0'",
    visibility: "invisible",
    save: "none",
    target: "user",
    rangeInf: false,
  },
  {
    id: 17,
    wt: 2,
    cumPerc: 49,
    roll: "48-49",
    name: "Apportation",
    discipline: "Psychokinesis",
    psp: "5 (stacking)",
    initiate: "1 min.",
    duration: "instant",
    range: "0'",
    visibility: "invisible",
    save: "neg.",
    target: "user +1 other creature",
    rangeInf: false,
  },
  {
    id: 18,
    wt: 3,
    cumPerc: 52,
    roll: "50-52",
    name: "Blink",
    discipline: "Psychokinesis",
    psp: 2,
    initiate: "1 action",
    duration: "instant",
    range: "120'",
    visibility: "invisible",
    save: "special",
    target: "user +1 other creature",
    rangeInf: false,
  },
  {
    id: 19,
    wt: 2,
    cumPerc: 54,
    roll: "53-54",
    name: "Mental Barrier",
    discipline: "Psychokinesis",
    psp: "5 (stacking)",
    initiate: "1 action",
    duration: "10 minutes",
    range: "120'",
    visibility: "visible",
    save: "special",
    target: "special",
    rangeInf: false,
  },
  {
    id: 20,
    wt: 3,
    cumPerc: 57,
    roll: "55-57",
    name: "Mind Thrust",
    discipline: "Psychokinesis",
    psp: "1 (stacking)",
    initiate: "1 action",
    duration: "instant",
    range: "0'",
    visibility: "invisible",
    save: "half",
    target: "special",
    rangeInf: false,
  },
  {
    id: 21,
    wt: 3,
    cumPerc: 60,
    roll: "58-60",
    name: "Psychic Crush",
    discipline: "Psychokinesis",
    psp: "3 (stacking)",
    initiate: "1 action",
    duration: "instant",
    range: "120'",
    visibility: "invisible",
    save: "half",
    target: "1 creature or object",
    rangeInf: false,
  },
  {
    id: 22,
    wt: 5,
    cumPerc: 65,
    roll: "61-65",
    name: "Psychokinesis",
    discipline: "Psychokinesis",
    psp: "2 (stacking)",
    initiate: "1 action",
    duration: "1 round",
    range: "120'",
    visibility: "invisible",
    save: "neg.",
    target: "1 creature or object",
    rangeInf: false,
  },
  {
    id: 23,
    wt: 3,
    cumPerc: 68,
    roll: "66-68",
    name: "Psychokinetic Deflection",
    discipline: "Psychokinesis",
    psp: 2,
    initiate: "Instant",
    duration: "instant",
    range: "0'",
    visibility: "invisible",
    save: "none",
    target: "1 missile or beam/ray",
    rangeInf: false,
  },
  {
    id: 24,
    wt: 2,
    cumPerc: 70,
    roll: "69-70",
    name: "Telekinetic Flight",
    discipline: "Psychokinesis",
    psp: 2,
    initiate: "1 action",
    duration: "1 minute",
    range: "0'",
    visibility: "invisible",
    save: "none",
    target: "user",
    rangeInf: false,
  },
  {
    id: 25,
    wt: 3,
    cumPerc: 73,
    roll: "71-73",
    name: "Domination",
    discipline: "Telepathy",
    psp: "2 (stacking)",
    initiate: "1 action",
    duration: "1 round",
    range: "120'",
    visibility: "invisible",
    save: "neg.",
    target: "1 intelligent creature",
    rangeInf: true,
  },
  {
    id: 26,
    wt: 3,
    cumPerc: 76,
    roll: "74-76",
    name: "Ego Whip",
    discipline: "Telepathy",
    psp: "2 (stacking)",
    initiate: "1 action",
    duration: "instant",
    range: "120'",
    visibility: "invisible",
    save: "neg.",
    target: "1 intelligent creature",
    rangeInf: false,
  },
  {
    id: 27,
    wt: 3,
    cumPerc: 79,
    roll: "77-79",
    name: "Mental Illusions",
    discipline: "Telepathy",
    psp: "2 (stacking)",
    initiate: "1 action",
    duration: "1 minute",
    range: "120'",
    visibility: "invisible",
    save: "neg.",
    target: "special",
    rangeInf: true,
  },
  {
    id: 28,
    wt: 2,
    cumPerc: 81,
    roll: "80-81",
    name: "Mental Paralysis",
    discipline: "Telepathy",
    psp: 3,
    initiate: "1 action",
    duration: "1 minute",
    range: "120'",
    visibility: "invisible",
    save: "neg.",
    target: "1 intelligent creature",
    rangeInf: false,
  },
  {
    id: 29,
    wt: 3,
    cumPerc: 84,
    roll: "82-84",
    name: "Mind Blank",
    discipline: "Telepathy",
    psp: 2,
    initiate: "1 action",
    duration: "1 hour",
    range: "0'",
    visibility: "invisible",
    save: "none",
    target: "user",
    rangeInf: false,
  },
  {
    id: 30,
    wt: 3,
    cumPerc: 87,
    roll: "85-87",
    name: "Mind Clouding",
    discipline: "Telepathy",
    psp: 2,
    initiate: "1 action",
    duration: "10 minutes",
    range: "60'",
    visibility: "invisible",
    save: "neg.",
    target: "1 creature or object",
    rangeInf: true,
  },
  {
    id: 31,
    wt: 2,
    cumPerc: 89,
    roll: "88-89",
    name: "Psionic Blast",
    discipline: "Telepathy",
    psp: "5 (stacking)",
    initiate: "Instant",
    duration: "instant",
    range: "0'",
    visibility: "visible",
    save: "half",
    target: "30' cone",
    rangeInf: true,
  },
  {
    id: 32,
    wt: 3,
    cumPerc: 92,
    roll: "90-92",
    name: "Suggestion",
    discipline: "Telepathy",
    psp: "3 (stacking)",
    initiate: "1 action",
    duration: "6 hours",
    range: "120'",
    visibility: "invisible",
    save: "neg.",
    target: "1 intelligent creature",
    rangeInf: false,
  },
  {
    id: 33,
    wt: 5,
    cumPerc: 97,
    roll: "93-97",
    name: "Telepathy",
    discipline: "Telepathy",
    psp: "1 (stacking)",
    initiate: "1 action",
    duration: "1 minute",
    range: "480'",
    visibility: "invisible",
    save: "neg.",
    target: "1 intelligent creature",
    rangeInf: true,
  },
  {
    id: 34,
    wt: 3,
    cumPerc: 100,
    roll: "98-100",
    name: "Thought Shield",
    discipline: "Telepathy",
    psp: 1,
    initiate: "1 round",
    duration: "10 minutes",
    range: "120'",
    visibility: "invisible",
    save: "none",
    target: "1 intelligent creature",
    rangeInf: true,
  },
];

export { psionicsData };
