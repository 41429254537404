const armorData = [
  {
    armor: "none",
    modifier: 0,
    penalty: "-",
  },
  {
    armor: "Leather",
    modifier: 2,
    penalty: "-",
  },
  {
    armor: "Padded",
    modifier: 2,
    penalty: "-",
  },
  {
    armor: "Ring mail",
    modifier: 3,
    penalty: -2,
  },
  {
    armor: "Studded leather",
    modifier: 3,
    penalty: -2,
  },
  {
    armor: "Cuir-bouilli",
    modifier: 3,
    penalty: -2,
  },
  {
    armor: "Scale mail",
    modifier: 5,
    penalty: -3,
  },
  {
    armor: "chain mail",
    modifier: 5,
    penalty: -3,
  },
  {
    armor: "Brigandine",
    modifier: 5,
    penalty: -3,
  },
  {
    armor: "Splint mail",
    modifier: 6,
    penalty: -3,
  },
  {
    armor: "Banded mail",
    modifier: 6,
    penalty: -3,
  },
  {
    armor: "Plate mail",
    modifier: 6,
    penalty: -3,
  },
  {
    armor: "Full plate mail",
    modifier: 8,
    penalty: -5,
  },
];

export { armorData };
