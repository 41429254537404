const mutationsData = [
  {
    id: 1,
    wt: 3,
    rollLow: 1,
    rollHigh: 3,
    roll: "1-3",
    name: "Acid Attack",
    desc: "",
  },
  {
    id: 2,
    wt: 2,
    rollLow: 4,
    rollHigh: 5,
    roll: "4-5",
    name: "Alacrity",
    desc: "",
  },
  {
    id: 3,
    wt: 2,
    rollLow: 6,
    rollHigh: 7,
    roll: "6-7",
    name: "Aquatic Adaptation",
    desc: "",
  },
  {
    id: 4,
    wt: 3,
    rollLow: 8,
    rollHigh: 10,
    roll: "9-10",
    name: "Bioluminescence",
    desc: "",
  },
  {
    id: 5,
    wt: 3,
    rollLow: 11,
    rollHigh: 13,
    roll: "11-13",
    name: "Chameleon Ability",
    desc: "",
  },
  {
    id: 6,
    wt: 3,
    rollLow: 14,
    rollHigh: 16,
    roll: "14-16",
    name: "Clinging",
    desc: "",
  },
  {
    id: 7,
    wt: 3,
    rollLow: 17,
    rollHigh: 19,
    roll: "17-19",
    name: "Compressible Form",
    desc: "",
  },
  {
    id: 8,
    wt: 3,
    rollLow: 20,
    rollHigh: 22,
    roll: "20-22",
    name: "Darksight",
    desc: "",
  },
  {
    id: 9,
    wt: 2,
    rollLow: 23,
    rollHigh: 24,
    roll: "23-24",
    name: "Dual Heads",
    desc: "",
  },
  {
    id: 10,
    wt: 3,
    rollLow: 25,
    rollHigh: 27,
    roll: "25-27",
    name: "Dwarfism",
    desc: "",
  },
  {
    id: 11,
    wt: 3,
    rollLow: 28,
    rollHigh: 30,
    roll: "28-30",
    name: "Echolocation",
    desc: "",
  },
  {
    id: 12,
    wt: 3,
    rollLow: 31,
    rollHigh: 33,
    roll: "31-33",
    name: "Electrogenic attack",
    desc: "",
  },
  {
    id: 13,
    wt: 3,
    rollLow: 34,
    rollHigh: 36,
    roll: "34-36",
    name: "Extra Arms",
    desc: "",
  },
  {
    id: 14,
    wt: 3,
    rollLow: 37,
    rollHigh: 39,
    roll: "37-39",
    name: "Extra Legs",
    desc: "",
  },
  {
    id: 15,
    wt: 3,
    rollLow: 40,
    rollHigh: 42,
    roll: "40-42",
    name: "Gas Attack",
    desc: "",
  },
  {
    id: 16,
    wt: 1,
    rollLow: 43,
    rollHigh: 43,
    roll: 43,
    name: "Gas Bags",
    desc: "",
  },
  {
    id: 17,
    wt: 3,
    rollLow: 44,
    rollHigh: 46,
    roll: "44-46",
    name: "Gigantism",
    desc: "",
  },
  {
    id: 18,
    wt: 3,
    rollLow: 47,
    rollHigh: 49,
    roll: "47-49",
    name: "Hyper Hearing",
    desc: "",
  },
  {
    id: 19,
    wt: 3,
    rollLow: 50,
    rollHigh: 52,
    roll: "50-21",
    name: "Hyper Immune System",
    desc: "",
  },
  {
    id: 20,
    wt: 3,
    rollLow: 53,
    rollHigh: 55,
    roll: "53-55",
    name: "Hyper Smell",
    desc: "",
  },
  {
    id: 21,
    wt: 3,
    rollLow: 56,
    rollHigh: 58,
    roll: "56-58",
    name: "Hyper Vision",
    desc: "",
  },
  {
    id: 22,
    wt: 1,
    rollLow: 59,
    rollHigh: 59,
    roll: 59,
    name: "Metamorphosis",
    desc: "",
  },
  {
    id: 23,
    wt: 3,
    rollLow: 60,
    rollHigh: 62,
    roll: "60-62",
    name: "Natural Armor",
    desc: "",
  },
  {
    id: 24,
    wt: 3,
    rollLow: 63,
    rollHigh: 65,
    roll: "63-65",
    name: "Natural Weaponry",
    desc: "",
  },
  {
    id: 25,
    wt: 1,
    rollLow: 66,
    rollHigh: 66,
    roll: 66,
    name: "Pheromonic Influence",
    desc: "",
  },
  {
    id: 26,
    wt: 3,
    rollLow: 67,
    rollHigh: 69,
    roll: "67-69",
    name: "Prehensile Tail",
    desc: "",
  },
  {
    id: 27,
    wt: 3,
    rollLow: 70,
    rollHigh: 72,
    roll: "70-72",
    name: "Radiant Gaze",
    desc: "",
  },
  {
    id: 28,
    wt: 3,
    rollLow: 73,
    rollHigh: 75,
    roll: "73-75",
    name: "Regeneration",
    desc: "",
  },
  {
    id: 29,
    wt: 3,
    rollLow: 76,
    rollHigh: 78,
    roll: "76-78",
    name: "Resistance",
    desc: "",
  },
  {
    id: 30,
    wt: 3,
    rollLow: 79,
    rollHigh: 81,
    roll: "79-81",
    name: "Sonic Attack",
    desc: "",
  },
  {
    id: 31,
    wt: 3,
    rollLow: 82,
    rollHigh: 84,
    roll: "82-84",
    name: "Spines, Thorns, or Quills",
    desc: "",
  },
  {
    id: 32,
    wt: 2,
    rollLow: 85,
    rollHigh: 86,
    roll: "85-86",
    name: "Symbiotic Domination",
    desc: "",
  },
  {
    id: 33,
    wt: 3,
    rollLow: 87,
    rollHigh: 89,
    roll: "87-89",
    name: "Thanatosis",
    desc: "",
  },
  {
    id: 34,
    wt: 2,
    rollLow: 90,
    rollHigh: 91,
    roll: "91-91",
    name: "Venom",
    desc: "",
  },
  {
    id: 35,
    wt: 3,
    rollLow: 92,
    rollHigh: 94,
    roll: "92-94",
    name: "Web Generation",
    desc: "",
  },
  {
    id: 36,
    wt: 3,
    rollLow: 95,
    rollHigh: 97,
    roll: "95-97",
    name: "Wings",
    desc: "",
  },
];

export { mutationsData };
