const monstersData = [
  {
    name: "Absorber Corpse",
    id: 1,
    genreAmericane: "",
    genreFantasy: "",
    genreRadium: "TRUE",
    isFitz: "TRUE",
    isBanned: "",
    nameLatin: "",
    type: "undead",
    freq: "very rare",
    Int: "High Human",
    alignment: "Neutral Evil",
    morale: "Fearless (DC 3)",
    numAppearing: 1,
    acADND: 1,
    acQR: 19,
    hd: 8,
    hdMod: 3,
    hpAvg: 39,
    level: 9,
    saveBonus: 3,
    attackNum: 1,
    attackDamage: "5-10",
    mv: 12,
    mvFly: 18,
    mvSwim: 0,
    xp: "",
    size: "man",
    psionicPSP: "",
    PsionicSavePenalty: "",
    aura: "Darkest black with Striations of dark red and dark green",
    attribStr: 18,
    attribDex: 18,
    attribCon: 18,
    attribInt: 15,
    attribWis: 16,
    attribCha: 10,
  },
  {
    name: "Buggbear",
    id: 2,
    genreAmericane: "",
    genreFantasy: "",
    genreRadium: "TRUE",
    isFitz: "TRUE",
    isBanned: "",
    nameLatin: "",
    type: "humanoid",
    freq: "uncommon",
    Int: "Low Human",
    alignment: "Chaotic Evil",
    morale: "Steady (DC 7)",
    numAppearing: "6-36",
    acADND: 5,
    acQR: 15,
    hd: 3,
    hdMod: 1,
    hpAvg: 15,
    level: 3,
    saveBonus: 1,
    attackNum: 1,
    attackDamage: "2-8 / weapon",
    mv: 9,
    mvFly: 0,
    mvSwim: 0,
    xp: "",
    size: "man",
    psionicPSP: "",
    PsionicSavePenalty: "",
    aura: "Green with dots of dark red and pale yellow",
    attribStr: 15,
    attribDex: 14,
    attribCon: 15,
    attribInt: 7,
    attribWis: 12,
    attribCha: 7,
  },
  {
    name: "Blarg",
    id: 3,
    genreAmericane: "",
    genreFantasy: "",
    genreRadium: "TRUE",
    isFitz: "TRUE",
    isBanned: "",
    nameLatin: "",
    type: "humanoid",
    freq: "rare",
    Int: "Human",
    alignment: "any",
    morale: "Average (DC 10)",
    numAppearing: "1-6",
    acADND: 5,
    acQR: 15,
    hd: 2,
    hdMod: 0,
    hpAvg: 9,
    level: 2,
    saveBonus: 1,
    attackNum: 1,
    attackDamage: "weapon",
    mv: 12,
    mvFly: 24,
    mvSwim: 0,
    xp: "",
    size: "man",
    psionicPSP: "",
    PsionicSavePenalty: "",
    aura: "any",
    attribStr: 12,
    attribDex: 10,
    attribCon: 10,
    attribInt: 10,
    attribWis: 10,
    attribCha: 6,
  },
  {
    name: "Cobol",
    id: 4,
    genreAmericane: "",
    genreFantasy: "",
    genreRadium: "TRUE",
    isFitz: "TRUE",
    isBanned: "",
    nameLatin: "",
    type: "humanoid",
    freq: "uncommon",
    Int: "Low Human",
    alignment: "Lawful Evil",
    morale: "Average (DC 10)",
    numAppearing: "40-400",
    acADND: 7,
    acQR: 13,
    hd: 0.5,
    hdMod: 0,
    hpAvg: 2,
    level: 1,
    saveBonus: 0,
    attackNum: 1,
    attackDamage: "1-4/weapon",
    mv: 9,
    mvFly: "",
    mvSwim: "",
    xp: "",
    size: "small",
    psionicPSP: "",
    PsionicSavePenalty: "",
    aura: "Pale yellow with bands of dark green",
    attribStr: 6,
    attribDex: 14,
    attribCon: 9,
    attribInt: 8,
    attribWis: 7,
    attribCha: 8,
  },
  {
    name: "Lizardman",
    id: 5,
    genreAmericane: "",
    genreFantasy: "TRUE",
    genreRadium: "TRUE",
    isFitz: "",
    isBanned: "",
    nameLatin: "",
    type: "humanoid",
    freq: "rare",
    Int: "Low Human",
    alignment: "Neutral",
    morale: "Average (DC 10)",
    numAppearing: "10-40",
    acADND: 5,
    acQR: 15,
    hd: 2,
    hdMod: 1,
    hpAvg: 10,
    level: 2,
    saveBonus: 1,
    attackNum: 3,
    attackDamage: "1-2/1-2/1-4",
    mv: 9,
    mvFly: 0,
    mvSwim: 12,
    xp: "",
    size: "man",
    psionicPSP: "",
    PsionicSavePenalty: "",
    aura: "Green",
    attribStr: 15,
    attribDex: 10,
    attribCon: 13,
    attribInt: 7,
    attribWis: 12,
    attribCha: 7,
  },
  {
    name: "Gate Mastiff",
    id: 6,
    genreAmericane: "",
    genreFantasy: "",
    genreRadium: "TRUE",
    isFitz: "TRUE",
    isBanned: "",
    nameLatin: "",
    type: "exotic beast",
    freq: "rare",
    Int: "High Animal",
    alignment: "Chaotic Good",
    morale: "Average (DC 10)",
    numAppearing: "1-2",
    acADND: 5,
    acQR: 15,
    hd: 4,
    hdMod: 0,
    hpAvg: 18,
    level: 3,
    saveBonus: 1,
    attackNum: 1,
    attackDamage: "1d6",
    mv: 16,
    mvFly: "",
    mvSwim: "",
    xp: "",
    size: "",
    psionicPSP: "",
    PsionicSavePenalty: "",
    aura: "Orange with blooms of silver and blue",
    attribStr: 13,
    attribDex: 14,
    attribCon: 14,
    attribInt: 8,
    attribWis: 13,
    attribCha: 11,
  },
  {
    name: "Clutch Vines",
    id: 7,
    genreAmericane: "",
    genreFantasy: "",
    genreRadium: "TRUE",
    isFitz: "TRUE",
    isBanned: "",
    nameLatin: "",
    type: "exotic plant",
    freq: "uncommon",
    Int: "none",
    alignment: "Neutral",
    morale: "Immnue (n/a)",
    numAppearing: "1-4",
    acADND: 6,
    acQR: 14,
    hd: 3,
    hdMod: 0,
    hpAvg: 14,
    level: 3,
    saveBonus: 1,
    attackNum: 1,
    attackDamage: "1-4",
    mv: 0,
    mvFly: "",
    mvSwim: "",
    xp: "",
    size: "",
    psionicPSP: "",
    PsionicSavePenalty: 0,
    aura: "Green",
    attribStr: 13,
    attribDex: 13,
    attribCon: 13,
    attribInt: 0,
    attribWis: 0,
    attribCha: 0,
  },
  {
    name: "Thought Slaver",
    id: 8,
    genreAmericane: "",
    genreFantasy: "",
    genreRadium: "TRUE",
    isFitz: "TRUE",
    isBanned: "",
    nameLatin: "",
    type: "exotic beast",
    freq: "rare",
    Int: "Genius",
    alignment: "Neutral Evil",
    morale: "Average (DC 10)",
    numAppearing: "1-4",
    acADND: 5,
    acQR: 15,
    hd: 8,
    hdMod: 4,
    hpAvg: 40,
    level: 12,
    saveBonus: 4,
    attackNum: 3,
    attackDamage: "1-2/1-2/1-8",
    mv: 12,
    mvFly: "",
    mvSwim: "",
    xp: "",
    size: "man",
    psionicPSP: 60,
    PsionicSavePenalty: 3,
    aura: "Squirming black and purple with blooming motes of gold",
    attribStr: 11,
    attribDex: 14,
    attribCon: 11,
    attribInt: 19,
    attribWis: 19,
    attribCha: 15,
  },
  {
    name: "Jade Juggernaut",
    id: 9,
    genreAmericane: "",
    genreFantasy: "",
    genreRadium: "TRUE",
    isFitz: "TRUE",
    isBanned: "",
    nameLatin: "",
    type: "Jade",
    freq: "very rare",
    Int: "None",
    alignment: "Neutral",
    morale: "Immnue (n/a)",
    numAppearing: 1,
    acADND: 2,
    acQR: 18,
    hd: 12,
    hdMod: 0,
    hpAvg: 54,
    level: 12,
    saveBonus: 4,
    attackNum: 1,
    attackDamage: "3-24",
    mv: 6,
    mvFly: 3,
    mvSwim: "",
    xp: "",
    size: "",
    psionicPSP: "",
    PsionicSavePenalty: "",
    aura: "none",
    attribStr: 22,
    attribDex: 9,
    attribCon: 20,
    attribInt: 3,
    attribWis: 11,
    attribCha: 1,
  },
];

export { monstersData };
